import React from 'react';
import Lottie from 'react-lottie';
import logo_car from '../animations/car-logo/logo_car.json';

const TestPage = () => {
  const defaultOptions = {
    animationData: logo_car,
  };
  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default TestPage;
